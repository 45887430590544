import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { FundDetails } from 'common/components/templates/FundDetails'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface FundDetailsPageProps {
    pageContext: any
}

export const FundDetailsPage: React.FC<FundDetailsPageProps> = ({
    pageContext
}) => {
    return <FundDetails {...getFundDetailsPageData(pageContext)} />
}

export default FundDetailsPage

export const getFundDetailsPageData = (data: any) => ({
    jumbotron: getJumbotronData(data),
    layout: getLayoutData(data),
    information: {
        title: data.title_information,
        text: (data.paragraphs || []).map(
            (paragraph: { text: string }) => paragraph.text
        )
    },
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    }
})
