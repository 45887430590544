import { Box, Container, Divider, HStack, Stack } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { HtmlContent } from '@irishlife/ilgroupdesignsystem.atoms.html-content'
import { Spinner } from '@irishlife/ilgroupdesignsystem.atoms.spinner'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import {
    ArrowLeftCircleIcon,
    DocumentDownloadIcon,
} from '@irishlife/ilgroupdesignsystem.icons'
import { Alert } from '@irishlife/ilgroupdesignsystem.molecules.alert'
import { navigate, useLocation } from '@reach/router'
import { AlertsProps } from 'common/components/organisms/Alerts'
import { CustomTooltip } from 'common/components/organisms/CustomTooltip'
import { InformationProps } from 'common/components/organisms/Information'
import { JumbotronProps } from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { PerformanceChartHeader } from 'common/components/organisms/PerformanceChartHeader'
import { useFundCentreUrls } from 'common/hooks/useFundCentreUrls'
import { useFundPerformanceQuery } from 'common/hooks/useFundPerformanceQuery'
import { useFundsQuery } from 'common/hooks/useFundsQuery'
import React from 'react'
import {
    Bar,
    BarChart,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

const months: Record<number, string> = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
}

type FundDetailsProps = {
    jumbotron: JumbotronProps
    layout: LayoutProps
    information: InformationProps
    alerts: AlertsProps
}

export function FundDetails(props: FundDetailsProps) {
    const {
        layout,
        information,
        alerts,
        jumbotron: { subtitle },
    } = props
    const { search } = useLocation()
    const { fundListUrl } = useFundCentreUrls()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, fundId] = search.split('=')

    const { data = [], status: dataStatus } = useFundsQuery()
    const { data: performance, status: performanceStatus } =
        useFundPerformanceQuery(fundId)

    const hasError = performanceStatus === 'error' || dataStatus === 'error'
    const isLoading =
        performanceStatus === 'loading' || dataStatus === 'loading'

    const onGoBack = () => navigate(fundListUrl)

    React.useEffect(() => {
        if (!fundId || hasError) navigate('/')
    }, [fundId, hasError])

    const fundData = React.useMemo(
        () => data.find(({ FundId }) => FundId === fundId) || {},
        [data, fundId]
    )

    const downloadFactSheet = React.useCallback(() => {
        window.open(fundData?.FundFactsheet || '', '_blank')
    }, [fundData])

    const chartData = React.useMemo(() => {
        if (!performance?.LineChart?.Data || !fundData.FundName) return []
        const fundName = fundData.FundName
        const dataArray: [number, number][] =
            JSON.parse(performance.LineChart.Data) || []
        return dataArray.reduce((acc, [timestamp, price]) => {
            const year = new Date(timestamp).getFullYear()
            acc.push({ value: price, year, timestamp, fundName })
            return acc
        }, [] as Record<string, string | number>[])
    }, [performance, fundData])

    const barChartData = React.useMemo(() => {
        if (!performance?.BarChart || !fundData.FundName) return []
        const fundName = fundData.FundName

        const { Data, Labels } = performance.BarChart
        const fundPrices = Data.split(',')
        const labels = Labels.split(',')

        return labels.reduce((acc, label, index) => {
            acc.push({ value: +fundPrices[index], year: +label, fundName })
            return acc
        }, [] as Record<string, string | number>[])
    }, [performance, fundData])

    const { latestDate, latestPrice } = React.useMemo(() => {
        return getLatestData(chartData)
    }, [chartData])

    if (isLoading) {
        return (
            <Layout {...layout}>
                <Stack
                    height='100vh'
                    pt={32}
                    isInline
                    width='full'
                    justifyContent='center'
                >
                    <Spinner size='lg' />
                </Stack>
            </Layout>
        )
    }

    return (
        <Layout {...layout} box={{ bgcolor: 'white' }}>
            <Container maxW='6xl'>
                <Stack py={[10, 16]}>
                    {subtitle && (
                        <Text variant={['title-sm', 'title-sm', 'title-md']}>
                            {subtitle}
                        </Text>
                    )}
                    <Text variant='title-2xl'>{fundData?.FundName}</Text>
                    <HtmlContent
                        sx={{
                            maxW: '3xl',
                            '* > *': { fontSize: '14px !important' },
                        }}
                    >
                        {information.text}
                    </HtmlContent>
                </Stack>
                <Box>
                    <Button
                        onClick={onGoBack}
                        leftIcon={<ArrowLeftCircleIcon fontSize={26} />}
                    >
                        Back to Fund List
                    </Button>
                </Box>
                <Stack
                    pt={16}
                    direction={['column-reverse', 'row']}
                    spacing={[10, 20]}
                    w='full'
                >
                    <Stack
                        w={['full', '50%']}
                        height={['300px', '350px']}
                        spacing={8}
                    >
                        <Text fontWeight='semibold' fontSize={18}>
                            Fund Price History
                        </Text>
                        <FundPerformanceChart data={chartData} />
                    </Stack>
                    <Stack w={['full', '50%']} spacing={5}>
                        <Text fontWeight='semibold' fontSize={18}>
                            Fund Details
                        </Text>
                        <LatestPerformance
                            fundRiskRating={fundData?.FundRiskRating}
                            date={latestDate}
                            value={latestPrice}
                        />
                        <HStack sx={{ p: 4 }}>
                            <Button
                                fontSize={24}
                                variant='ghost'
                                fontWeight='bold'
                                onClick={downloadFactSheet}
                                leftIcon={
                                    <DocumentDownloadIcon fontSize={22} />
                                }
                            >
                                Fund Factsheet
                            </Button>
                        </HStack>
                    </Stack>
                </Stack>
                <Stack
                    direction={['column-reverse', 'row']}
                    pt={[10, 12]}
                    spacing={[10, 20]}
                    w='full'
                >
                    <Stack w={['full', '50%']} spacing={8}>
                        <Text fontWeight='semibold' fontSize={18}>
                            Historical Fund Performance
                        </Text>
                        <HistoricalPerformance {...fundData} />
                    </Stack>
                    <Stack
                        w={['full', '50%']}
                        height={['300px', '350px']}
                        spacing={8}
                    >
                        <Text fontWeight='semibold' fontSize={18}>
                            Yearly Fund Performance
                        </Text>
                        <FundPerformanceBarChart data={barChartData} />
                    </Stack>
                </Stack>
                <Box pt={10}>
                    <Button
                        onClick={onGoBack}
                        leftIcon={<ArrowLeftCircleIcon fontSize={26} />}
                    >
                        Back to Fund List
                    </Button>
                </Box>
                <Stack maxW='xl' py={[10, 16]}>
                    {alerts.alerts.map((alert) => (
                        <Alert
                            key={alert}
                            status='warning'
                            textProps={{ fontWeight: 'bold' }}
                        >
                            {alert}
                        </Alert>
                    ))}
                </Stack>
            </Container>
        </Layout>
    )
}

type LatestPerformanceProps = {
    fundRiskRating?: string
    date?: string
    value?: number
}

function LatestPerformance(props: LatestPerformanceProps) {
    const { fundRiskRating, date, value } = props
    return (
        <HStack>
            <Stack isInline spacing={10} p={4} rounded='md' shadow='lg'>
                <Stack sx={{ p: { fontWeight: 'normal' } }} spacing={3}>
                    <Text>Price:</Text>
                    <Text>Price Date:</Text>
                    <Text>Rating:</Text>
                </Stack>
                <Divider orientation='vertical' />
                <Stack sx={{ p: { fontWeight: 'semibold' } }} spacing={3}>
                    <Text>€{value}</Text>
                    <Text>{date}</Text>
                    <Text>IL{fundRiskRating}</Text>
                </Stack>
            </Stack>
        </HStack>
    )
}

function HistoricalPerformance(props: Record<string, string>) {
    const {
        OneMonthGrowthPercent = '',
        ThreeMonthGrowthPercent = '',
        OneYearGrowthPercent = '',
        ThreeYearGrowthPercent = '',
        SinceLaunchPercent = '',
    } = props

    return (
        <Stack isInline spacing={10} w='full'>
            <Stack sx={{ p: { fontWeight: 'normal' } }} spacing={3}>
                <Text>1 month:</Text>
                <Text>3 months:</Text>
                <Text>1 year:</Text>
                <Text>3 years:</Text>
                <Text>Since launch:</Text>
            </Stack>
            <Divider orientation='vertical' />
            <Stack sx={{ p: { fontWeight: 'semibold' } }} spacing={3}>
                <Text>{formatToPercent(OneMonthGrowthPercent)}</Text>
                <Text>{formatToPercent(ThreeMonthGrowthPercent)}</Text>
                <Text>{formatToPercent(OneYearGrowthPercent)}</Text>
                <Text>{formatToPercent(ThreeYearGrowthPercent)}</Text>
                <Text>{formatToPercent(SinceLaunchPercent)}</Text>
            </Stack>
        </Stack>
    )
}

function formatToPercent(value: string) {
    return value === 'N/A' ? '-' : `${value}%`
}

export interface FundCentrePageProps {
    pageContext: any
}

function FundPerformanceChart({
    data,
}: {
    data: Record<string, string | number>[]
}) {
    const [historicalData, setHistoricalData] = React.useState([])

    return (
        <Stack w='full' h='full'>
            <PerformanceChartHeader data={data} onChange={setHistoricalData} />
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                    data={historicalData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey='year' />
                    <YAxis domain={['auto', 'auto']} hide />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                        type='monotone'
                        dataKey='value'
                        stroke='#5162AC'
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Stack>
    )
}

export function FundPerformanceBarChart({
    data,
}: {
    data: Record<string, string | number>[]
}) {
    return (
        <Stack w='full' h='full'>
            <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey='year' />
                    <YAxis domain={['auto', 'auto']} hide />
                    <Tooltip content={<CustomTooltip />} />
                    <ReferenceLine y={0} stroke='#000' />
                    <Bar dataKey='value' fill='#5162AC' />
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    )
}

function getLatestData(chartData: Record<string, number | string>[]) {
    if (!chartData || chartData.length === 0) return {}
    const { value: latestPrice, timestamp } = chartData[
        chartData.length - 1
    ] as { value: number; timestamp: number }
    const realDate = new Date(timestamp)
    const latestDate = `${realDate.getDate()} ${
        months[realDate.getMonth()]
    } ${realDate.getFullYear()}`
    return { latestDate, latestPrice }
}
